// app/javascript/packs/application.js

// Rails が提供するライブラリやモジュールをインポートすることができます。
import Rails from "@rails/ujs";

// Turbolinks を有効にする場合はインポートします。
import Turbolinks from "turbolinks";

// ページが読み込まれた際に実行される関数を定義します。
document.addEventListener("DOMContentLoaded", () => {
  // Turbolinks を有効にします。
  Turbolinks.start();
});

// Rails UJS を有効にします。
Rails.start();
